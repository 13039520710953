import { Title, Section } from "@vshp/ui-components";

import ObrnadzorLogoIcon from "../../../public/images/accreditation/obrnadzor.png";
import MinobrLogoIcon from "../../../public/images/accreditation/minobr.png";
import ARSKILogoIcon from "../../../public/images/accreditation/ARSKI.png";
import RospatentLogoIcon from "../../../public/images/accreditation/rospatent.png";

function Accreditation() {
  return (
    <Section border={true}>
      <Title type="h2">Аккредитации, сертификации и партнёрства</Title>
      <div className="accreditation">
        <a
          className="accreditation__item"
          href="https://obrnadzor.gov.ru/gosudarstvennye-uslugi-i-funkczii/gosudarstvennye-uslugi/liczenzirovanie-obrazovatelnoj-deyatelnosti/svodnyj-reestr-liczenzij"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <img src={ObrnadzorLogoIcon.src} alt="obrnadzor-logo" />
          </span>
        </a>
        <a className="accreditation__item" href="https://minobr.tverreg.ru" target="_blank" rel="noopener noreferrer">
          <span>
            <img src={MinobrLogoIcon.src} alt="minobr-logo" />
          </span>
        </a>
        <a className="accreditation__item" href="https://institute.vshp.online/docs/88a3/Svidetelstvo.pdf" target="_blank" rel="noopener noreferrer">
          <span>
            <img src={ARSKILogoIcon.src} alt="ARSKI-logo" />
          </span>
        </a>
        <a className="accreditation__item" href="https://fips.ru/EGD/ad1e7560-c185-47a4-a930-40d9b87183a0" target="_blank" rel="noopener noreferrer">
          <span>
            <img src={RospatentLogoIcon.src} alt="rospatent-logo" />
          </span>
        </a>
      </div>
    </Section>
  );
}

export default Accreditation;
