import React from "react";
import { useSelector } from "react-redux";
import { renderProgramCard } from "../utils/render-program-card";
import { Title, Section } from "@vshp/ui-components";

const Programs = () => {
  const { programs } = useSelector((state) => state.programs);

  return (
    programs.length > 0 && (
      <Section border={true}>
        <Title type="h2">Программы обучения</Title>
        <div className="programs-feed__wrapper">{renderProgramCard(programs)}</div>
      </Section>
    )
  );
};

export default Programs;
