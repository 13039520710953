import React from "react";
import Link from "next/link";
import { useSelector } from "react-redux";

import { Title, Section, Steps, LinkDecor } from "@vshp/ui-components";
import { fetchOrgSettings } from "../actions/settings";
import { fetchPrograms } from "../actions/programs";
import { fetchOrgContacts } from "../actions/contacts";
import { fetchPostsRecent } from "../actions/posts";
import { fetchVideos } from "../actions/videos";

import Applicants from "../components/shared/Applicants";
import Contacts from "../components/shared/Contacts";
import Accreditation from "../components/shared/Accreditation";
import Consultation from "../components/shared/Consultation";
import Videos from "../components/shared/Videos";
import Programs from "../components/shared/Programs";
import Greeting from "../components/shared/Greeting";
import Teachers from "../components/shared/Teachers";
import Certificates from "../components/shared/Certificates";
import Reviews from "../components/shared/Reviews";
import Partners from "../components/shared/Partners";
import Faq from "../components/shared/Faq";
import Offer from "../components/shared/Offer";
import Life from "../components/shared/Life";
import PostsRecent from "../components/shared/PostsRecent";
import VideosInfo from "../components/shared/VideosInfo";

import { wrapper } from "../store";

export const getServerSideProps = wrapper.getServerSideProps((store) => async () => {
  await store.dispatch(fetchOrgSettings());
  await store.dispatch(fetchPrograms());
  await store.dispatch(fetchOrgContacts());
  await store.dispatch(fetchPostsRecent());
  await store.dispatch(fetchVideos());
});

function Home() {
  const { settingsOrg } = useSelector((state) => state.settingsOrg);

  return (
    <div className="main">
      <div className="page__header main__intro intro">
        <div className="intro__video">
          <video loop autoPlay muted playsInline preload="auto">
            <source src="/video/vshp.ogv" type='video/ogg; codecs="theora, vorbis"' />
            <source src="/video/vshp.webm" type='video/webm; codecs="vp8, vorbis"' />
            <source src="/video/vshp.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
          </video>
        </div>
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title main__intro-title">
            <span dangerouslySetInnerHTML={{ __html: settingsOrg.title }}></span>
          </Title>
          <div className="page__header-subtitle" dangerouslySetInnerHTML={{ __html: settingsOrg.description }}></div>
          <Link className="btn btn_primary page__header-btn" href={settingsOrg.pages[0].children[0].slug}>
            {settingsOrg.pages[0].children[0].title}
          </Link>
        </div>
      </div>

      <div className="page__blocks">
        <Section className="wysiwyg" border={true}>
          <Title type="h2">Об институте</Title>
          <Section.BodySmall>
            <p>
              &laquo;Высшая школа предпринимательства&raquo;&nbsp;&mdash; это уникальное высшее учебное заведение в&nbsp;Твери, которое осуществляет не&nbsp;только очный образовательный процесс,
              но&nbsp;и&nbsp;обучение с&nbsp;помощью дистанционных технологий. Главный принцип работы института&nbsp;&mdash; практический опыт во&nbsp;время учебы, что дает студентам возможность
              профессионально развиваться ещё до&nbsp;выпуска. В&nbsp;учебном процессе преподаватели используют современные технологии, а&nbsp;руководство продолжает развивать институт, делая упор
              на&nbsp;blended learning&nbsp;&mdash; метод, сочетающий очное и&nbsp;дистанционное взаимодействие между педагогами и&nbsp;студентами. Программы института разработаны с&nbsp;учетом
              международного опыта подготовки специалистов различных профилей. Такой подход к&nbsp;подготовке бакалавров, магистров и&nbsp;аспирантов позволяет институту выпускать специалистов, широко
              востребованных на&nbsp;современном рынке труда.
            </p>
          </Section.BodySmall>
        </Section>

        <Section className="note wysiwyg">
          <Title type="h2">Преимущества института</Title>

          <div className="list-cards">
            <div className="list-cards__item">Запатентованная образовательная платформа</div>
            <div className="list-cards__item">Возможность обучаться заочно с&nbsp;применением дистанционных технологий</div>
            <div className="list-cards__item">Современная материально-техническая база</div>
            <div className="list-cards__item">Преподавательский состав с&nbsp;многолетним опытом работы</div>
            <div className="list-cards__item">Обучение по востребованным специальностям</div>
            <div className="list-cards__item">Фиксированная стоимость обучения на весь срок</div>
            <div className="list-cards__item">Собственный образовательный портал и личные кабинеты</div>
            <div className="list-cards__item">Возможность получать образование из дома</div>
            <div className="list-cards__item">Защита персональных данных студентов</div>
          </div>
        </Section>

        <Consultation />
        <Videos />
        <Greeting />
        <Programs />
        <VideosInfo />

        <Section border={true}>
          <Title type="h2">Как проходит обучение</Title>
          <Steps>
            <Steps.Item title="ЗНАКОМСТВО" description="Сотрудник приемной комиссии проведет для вас экскурсию по&nbsp;учебному заведению и&nbsp;расскажет историю института."></Steps.Item>
            <Steps.Item
              title="Поступление"
              description="Для поступления достаточно предоставить только пакет необходимых документов. Далее консультант подготовит договор на&nbsp;обучение и&nbsp;создаст для вас личный кабинет на&nbsp;портале."
            />
            <Steps.Item
              title="Обучение"
              description="Студенты очной формы посещают лекции и&nbsp;семинары, которые проходят в&nbsp;здании института. Студенты-заочники учатся через мобильное приложение или образовательный портал. На&nbsp;сессию приезжать не&nbsp;нужно."
            />
            <Steps.Item title="СТУДЕНЧЕСКАЯ ЖИЗНЬ">
              В&nbsp;течение учебного года наши студенты участвуют в&nbsp;спортивных и&nbsp;культурных мероприятиях, посещают тренинги, семинары и&nbsp;познавательные лекции в&nbsp;области
              предпринимательства, экономики и&nbsp;права.
            </Steps.Item>
            <Steps.Item
              title="ГОСУДАРСТВЕННЫЙ ЭКЗАМЕН"
              description="На&nbsp;выпускном семестре студенты сдают устный госэкзамен для проверки знаний и&nbsp;готовности к&nbsp;выполнению профессиональных обязанностей. Экзамен принимает государственная экзаменационная комиссия (ГЭК)."
            />
            <Steps.Item
              title="ПРАКТИКА И ТРУДОУСТРОЙСТВО"
              description="Институт &laquo;Высшая школа предпринимательства&raquo; направляет студентов на&nbsp;практику в&nbsp;компании-партнеры с&nbsp;дальнейшим трудоустройством, например, в&nbsp;ПАО Сбербанке, Страховой группе &laquo;Уралсиб&raquo;, Пенсионном фонде&nbsp;РФ и&nbsp;других."
            />

            <Steps.Item title="ИТОГОВЫЕ ДОКУМЕНТЫ" description="По&nbsp;окончании обучения выпускники получают диплом бакалавра, магистра или аспиранта." />
            <Steps.Item
              title="ВЫПУСКНОЙ"
              description="Каждый год институт организовывает торжественное вручение дипломов. Уже по&nbsp;традиции наряженные в&nbsp;мантии выпускники бросают в&nbsp;небо шапочки-конфедератки."
            />
            <Steps.Item
              title="ДАЛЬНЕЙШЕЕ ОБРАЗОВАНИЕ"
              description="Комплекс &laquo;Высшая школа предпринимательства&raquo; обеспечивает систему непрерывного образования. Выпускники с&nbsp;дипломом бакалавра могут поступить в&nbsp;магистратуру, а&nbsp;с&nbsp;дипломом магистра&nbsp;&mdash; в&nbsp;аспирантуру института."
            />
          </Steps>
        </Section>

        <Life />
        <Teachers />
        <Certificates />

        <Section>
          <Title type="h2">ОФИЦИАЛЬНЫЕ ДОКУМЕНТЫ</Title>

          <div className="wysiwyg__documents">
            <LinkDecor link="https://cdn.vshp.online/docs/8c73/Reestrovaya_vypiska.pdf">Выписка из реестра лицензий</LinkDecor>
            <LinkDecor link="https://vshp.online/docs/8d96/ROSPATENT_-_SVIDETELSTVO_-_Ed.ERP_-_SKAN.pdf">
              Свидетельство о государственной регистрации электронной информационно-образовательной среды
            </LinkDecor>
            <LinkDecor link="https://cdn.vshp.online/docs/82e8/Ofitsialnaya_prezentatsiya_Chastnogo_uchrezhdeniya_vysshego_obrazovaniya__Vysshaya_shkola_predprinimatelstva_..pdf">
              Официальная презентация Частного учреждения высшего образования &laquo;Высшая школа предпринимательства&raquo;
            </LinkDecor>
          </div>
        </Section>

        <Reviews />
        <Partners />

        <Applicants slug={settingsOrg.pages[0].children[1].slug} />
        <Accreditation />
        <Faq />
        <Offer />
        <PostsRecent />
        <Contacts />
      </div>
    </div>
  );
}

export default Home;
