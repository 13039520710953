import { Title, Section } from "@vshp/ui-components";
import { PlayBtnIcon } from "../utils/svg";
import { VIDEOS_INFO } from "../../constants/videos";
import Slider from "../utils/Slider";
import Image from "next/image";

function Videos() {
  const settings = {
    slidesToShow: 3,
    infinite: VIDEOS_INFO.length > 3,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          arrows: false,
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1075,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Section className="videos">
      <Title type="h2">Как зарегистрироваться, оплатить и начать обучение на сайте</Title>
      <div className="videos__slider">
        <Slider className="videos__slider-inner slider__dots_accent" sliderSettings={settings}>
          {VIDEOS_INFO.map((video, key) => (
            <div className="slider__item" key={key}>
              <div className="videos__item">
                <a href={video.url} className="videos__img-wrap" target="_blank" rel="noopener noreferrer">
                  <div className="videos__img-icon">
                    <PlayBtnIcon />
                  </div>
                  <Image className="videos__img" src={video.preview} alt={video.title} />
                </a>
                <h3 className="t-h3 videos__title">
                  <a href={video.url} target="_blank" rel="noopener noreferrer">
                    {video.title}
                  </a>
                </h3>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  );
}

export default Videos;
