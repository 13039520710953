import { Title, Section, Button } from "@vshp/ui-components";
import programConsultationBg from "../../../public/images/consultation.jpg";
import { URL_PROFILE } from "../../config/config";
import Image from "next/image";

const Consultation = () => {
  return (
    <section className="page__block page__block_cover page__block_center page__block_cover_white">
      <Image className="page__block-img" src={programConsultationBg} alt="Получите консультацию у специалиста!" />
      <div className="page__block-body">
        <Title type="h2" decor={false}>
          Получите консультацию у специалиста!
        </Title>
        <Section.BodySmall>
          <div className="wysiwyg">
            <p>
              Если у вас есть вопросы по обучению, мы с удовольствием проконсультируем вас.
              <br />
              Напишите нам и мы подберем вам программу обучения, поможем с переводом из другого учебного заведения и т.д.
            </p>
          </div>

          <Button href={`${URL_PROFILE}/sign_in`} spaceTop={true} target="_blank" rel="noopener noreferrer">
            Задать вопрос
          </Button>
        </Section.BodySmall>
      </div>
    </section>
  );
};

export default Consultation;
