import Link from "next/link";
import { formatDate } from "../utils/format-date";
import cn from "classnames";
import { useRouter } from "next/router";

function PostCard({ item }) {
  const { locale } = useRouter();
  const currentItem = item.attributes;

  const postItemClass = cn("post-item__image-link", {
    "post-item__image-link-favorite": currentItem.favorite,
  });

  return (
    <div className="posts-feed__item post-item">
      <Link href={`/blog/${item.type}/${currentItem.slug}`} className={postItemClass}>
        <img className="post-item__image" src={currentItem.photo.preview} alt={currentItem.title} />
        <img className="post-item__image post-item__image_mobile" src={currentItem.photo["preview-mobile"]} alt={currentItem.title} />
      </Link>
      <div className="post-item__date">
        <span>{formatDate(locale, currentItem["published-at"])}</span>
        <div className="post-item__date-separator">|</div>
        <span data-key="posts.posts">{item.type === "posts" ? "Новости" : "Пресса"}</span>
      </div>
      <div className="post-item__title">
        <Link href={`/blog/${item.type}/${currentItem.slug}`}>{currentItem.title}</Link>
      </div>
      <div className="post-item__intro">
        <p>{currentItem.excerpt}</p>
      </div>
      <Link href={`/blog/${item.type}/${currentItem.slug}`} data-key="ui.see_more" className="post-item__more">
        Подробнее {">"}
      </Link>
    </div>
  );
}

export default PostCard;
