import { Card } from "@vshp/ui-components";

function ProgramCard({ item }) {
  return (
    <Card className="programs-feed__item" cover={<img alt={item.attributes.title} src={item.attributes.photo.preview} />} title={item.attributes.title}>
      <Card.Row>
        <Card.Title>Уровень подготовки</Card.Title>
        <Card.Subtitle>{item.attributes["degree-name"]}</Card.Subtitle>
      </Card.Row>
      {item.attributes.specialty != null && (
        <Card.Row>
          <Card.Title>Специальность</Card.Title>
          <Card.Subtitle>{item.attributes.specialty}</Card.Subtitle>
        </Card.Row>
      )}
      <Card.Row>
        <Card.Title>Язык</Card.Title>
        <Card.Subtitle>{item.attributes["language-name"]}</Card.Subtitle>
      </Card.Row>
    </Card>
  );
}

export default ProgramCard;
