function PartnerItem({ partners }) {
  return (
    <div className="partners">
      {partners.length > 0 &&
        partners.map((partner, key) => (
          <a className="partners__item" href={partner.url} key={key} target="_blank" rel="noopener noreferrer">
            <img src={partner.logo.src} alt={partner.title} />
          </a>
        ))}
    </div>
  );
}

export default PartnerItem;
