import { Title } from "@vshp/ui-components";
import Slider from "../utils/Slider";
import reviewsBg from "../../../public/images/reviews.jpg";

import reviewPicture1 from "../../../public/images/reviews/review_01.jpg";
import reviewPicture2 from "../../../public/images/reviews/review_02.jpg";
import reviewPicture3 from "../../../public/images/reviews/review_03.jpg";
import reviewPicture4 from "../../../public/images/reviews/review_04.png";
import reviewPicture5 from "../../../public/images/reviews/review_05.png";
import Image from "next/image";

const reviews = [
  {
    photo: reviewPicture1,
    name: "Селезнева Татьяна Геннадьевна",
    position: "Студентка бакалавриата",
    description: `<p>Во-первых хотелось&nbsp;бы отметить помощь кураторов, справляются со&nbsp;своей работой на&nbsp;отлично, очень оперативно отвечают на&nbsp;все поставленные вопросы.
    </p>
    <p>
    Во-вторых портал &laquo;ВШП&raquo; удобен для обучения, всё сделано для удобства студента, всё очень понятно. С&nbsp;преподавателями всегда есть обратная связь, что тоже не&nbsp;маловажно.
    </p>
    <p>
    В-третьих нужно отметить, что такое обучение можно совмещать с&nbsp;работой, что очень удобно.
    </p>
    <p>
    Получила свой диплом по&nbsp;обучению и&nbsp;продолжила учиться дальше, потому что уверена в&nbsp;этой образовательной организации.
    </p>
    <p>
    Хочу поблагодарить команду &laquo;ВШП&raquo; за&nbsp;помощь и&nbsp;поддержку в&nbsp;обучении. Процветания вам и&nbsp;благодарных студентов!
    </p>`,
  },
  {
    photo: reviewPicture2,
    name: "Гололобова Елена Сергеевна",
    position: "Студентка бакалавриата",
    description: `<p>Очень удобная платформа обучения, деканат идеальный, преподаватели в&nbsp;видеолекциях объясняют очень доступно и&nbsp;понятно.
    </p>
    <p>
    Особую благодарность хочу выразить своему куратору Грянцевой Нинель Александровне! Её&nbsp;сопровождение и&nbsp;мотивация для меня ценны.
    </p>
    <p>
    Также благодарю всех преподавателей, которые дают знания и&nbsp;делятся своим опытом.
    </p>
    <p>
    Ещё хочу сказать, что обучение в&nbsp;университете удобно тем, что не&nbsp;нужно никуда ехать всё дистанционно, без отрыва от&nbsp;семьи и&nbsp;работы.
    </p>
    <p>
    Также скажу про оплату обучения: очень бюджетно, меня это устраивает.
    </p>
    <p>
    Рекомендую университет к&nbsp;поступлению.
    </p>`,
  },
  {
    photo: reviewPicture3,
    name: "Бадрутдинов Радик Робертович",
    position: "Выпускник бакалавриата",
    description: `<p>Выражаю благодарность за&nbsp;предоставленную возможность получить качественное высшее образование.
    </p>
    <p>
    Высококвалифицированный педагогический состав, организационность и&nbsp;профессиональность.
    </p>
    <p>
    Закончил учиться, работаю руководителем. Кто хочет освоить программу, обязательно освоит! Университ дает возможность стать профессионалом в&nbsp;выбранной сфере, подходя к&nbsp;своей задаче качественно и&nbsp;неординарно.
    </p>`,
  },
  {
    photo: reviewPicture4,
    name: "Завьялова Анастасия Сергеевна",
    position: "Выпускник бакалавриата",
    description: `<p>Очень понравилась организация учебного процесса тем, что очень удобно. Можно выходить на&nbsp;образовательную платформу платформу в&nbsp;любое время. Если возникали технические неполадки, то&nbsp;они очень быстро устранялись при обращении к&nbsp;куратору&nbsp;&mdash; у&nbsp;каждого студента он&nbsp;свой. Мне было очень легко учиться благодаря поддержке Гранцевой Нинель Александровны. Она очень грамотный специалист и&nbsp;замечательная девушка.
    </p>
    <p>
    Программа предоставлена в&nbsp;очень доступной для восприятия форме. Цены в&nbsp;&laquo;ВШП&raquo; очень доступные.
    </p>
    <p>
    Рекомендую, хороший вуз.
    </p>`,
  },
  {
    photo: reviewPicture5,
    name: "Ильина Анна Юрьевна",
    position: "Выпускник бакалавриата",
    description: `<p>Хочу выразить благодарность &laquo;Высшей школе предпринимательства&raquo;! Очень удобно и&nbsp;грамотно продумано обучение для тех, кто работает и&nbsp;хочет получить высшее образование. Приёмная комиссия всё доступно объясняет, помогают с&nbsp;выбором направления, очень доброжелательны.
    </p>
    <p>
    Отдельную благодарность хочу выразить своему куратору Гранцевой Нинель. Все 4&nbsp;года моего обучения она всегда была на&nbsp;связи, помогала во&nbsp;всех вопросах. Очень добрая и&nbsp;обаятельная девушка.
    </p>
    <p>
    Закончила бакалавриат, теперь собираюсь поступать на&nbsp;магистратуру. Советую всем &laquo;ВШП&raquo;. Молодцы! Хорошая КОМАНДА!
    </p>`,
  },
];

function Reviews() {
  const settings = {
    infinite: true,
  };

  return (
    <section className="page__block page__block_cover">
      <Image className="page__block-img" src={reviewsBg} placeholder="blur" alt="ОТЗЫВЫ"/>
      <div className="page__block-body">
        <Title type="h2" decor={false}>
          ОТЗЫВЫ
        </Title>

        <Slider className="slider-description" sliderSettings={settings}>
          {reviews.map((teacher, key) => (
            <div className="slider__item" key={key}>
              <div className="slider-description__item">
                <div className="slider-description__header">
                  <Image className="slider-description__photo" src={teacher.photo} alt={teacher.name} placeholder="blur" />
                  <div className="slider-description__name">{teacher.name}</div>
                  <div className="slider-description__post" dangerouslySetInnerHTML={{ __html: teacher.position }}></div>
                </div>
                <div className="slider-description__content" dangerouslySetInnerHTML={{ __html: teacher.description }}></div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Reviews;
