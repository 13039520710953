import { Title, Button } from "@vshp/ui-components";
import teachersBg from "../../../public/images/teachers.jpg";

import teacher01 from "../../../public/images/teachers/teacher01.png";
import teacher02 from "../../../public/images/teachers/teacher02.jpg";
// import teacher03 from "../../../public/images/teachers/teacher03.jpeg";
import teacher04 from "../../../public/images/teachers/teacher04.jpg";

import Slider from "../utils/Slider";
import Image from "next/image";
import { URL } from "../../config/config";

const teachers = [
  {
    photo: teacher01,
    name: "Царёва Наталья Евгеньевна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `<p>Кандидат экономических наук, доцент и&nbsp;преподаватель кафедры &laquo;Финансы&raquo; Тверского государственного университета; член и&nbsp;секретарь ученого Совета Института экономики и&nbsp;управления ТвГУ. Является независимым экспертом в&nbsp;работе аттестационной комиссии Управления федерального казначейства по&nbsp;Тверской области.</p>
    `,
  },
  {
    photo: teacher02,
    name: "Толкаченко Галина Львовна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства». Проректор института «Высшая школа предпринимательства».",
    description: `<p>Кандидат экономических наук, профессор, почетный работник высшего профессионального образования. Обладает званием &laquo;Почетный профессор Тверского государственного университета&raquo;.</p>
    <p>Преподаватель по&nbsp;направлениям &laquo;Банковское дело&raquo; и&nbsp;&laquo;Коммерция (по&nbsp;отраслям)&raquo;. Создатель ряда авторских курсов по&nbsp;экономике и&nbsp;финансовому менеджменту, автор более 150-ти научных статей, 8-ми монографий по&nbsp;вопросам инвестиционной деятельности, 4-х учебных пособий с&nbsp;грифом УМО.</p>
    `,
  },
  // {
  //   photo: teacher03,
  //   name: "Ткачёв Павел Сергеевич",
  //   position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
  //   description: `<p>Кандидат философских наук, эксперт чемпионата WorldSkills. Ведущий преподаватель по направлению «Информационные системы и программирование».</p>
  //   <p>Образовательная деятельность:</p>
  //   <ul>
  //     <li>Заведующий кафедрой информационных технологий института «Высшая школа предпринимательства»;</li>
  //     <li>Проректор по информатизации.</li>
  //   </ul>
  //   `,
  // },
  {
    photo: teacher04,
    name: "Толкаченко Оксана Юрьевна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `<p>Кандидат экономических наук, доцент. Специалист в&nbsp;области экономики, менеджмента и&nbsp;психологии.</p>
    <p>Является экспертом в&nbsp;области проведения государственной аттестации образовательного учреждения. Также занимается разработкой основных образовательных программ бакалавриата. Автор более 60&nbsp;научных публикаций.</p>
    <p>Образовательная деятельность:</p>
    <ul>
      <li>Преподаватель Тверского государственного университета — ТвГУ.</li>
    </ul>`,
  },
];

function Teachers() {
  const settings = {
    infinite: true,
  };

  return (
    <section className="page__block page__block_cover">
      <Image className="page__block-img" src={teachersBg} alt="Преподаватели" placeholder="blur" />
      <div className="page__block-body">
        <Title type="h2" decor={false}>
          Преподаватели
        </Title>

        <Slider className="slider-description" sliderSettings={settings}>
          {teachers.map((teacher, key) => (
            <div className="slider__item" key={key}>
              <div className="slider-description__item">
                <div className="slider-description__header">
                  <Image className="slider-description__photo" src={teacher.photo} alt={teacher.name} placeholder="blur" />
                  <div className="slider-description__name">{teacher.name}</div>
                  <div className="slider-description__post" dangerouslySetInnerHTML={{ __html: teacher.position }}></div>
                </div>
                <div className="slider-description__content" dangerouslySetInnerHTML={{ __html: teacher.description }}></div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-center">
          <Button spaceTop={true} href={`${URL}/teachers`} target="_blank" rel="noopener noreferrer">
            Узнать больше преподавателей
          </Button>
        </div>
      </div>
    </section>
  );
}

export default Teachers;
