import { useSelector } from "react-redux";
import Link from "next/link";
import { Title } from "@vshp/ui-components";
import PostCard from "../posts/PostCard";

const PostsRecent = () => {
  const { postsRecent } = useSelector((state) => state.posts);

  return postsRecent.length > 0 ? (
    <div className="page__block">
      <Title type="h2">Новости</Title>

      <div className="post__recent-items posts-feed__wrapper">
        {postsRecent.map((post, id) => (
          <PostCard item={post} key={id} />
        ))}
      </div>

      <div className="text-center">
        <Link className="btn btn_primary btn__more" href="/blog">
          Все новости
        </Link>
      </div>
    </div>
  ) : null;
};

export default PostsRecent;
