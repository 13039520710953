import greetingBg from "../../../public/images/diploma.png";
import rector from "../../../public/images/rector.jpg";
import { Title } from "@vshp/ui-components";
import Image from "next/image";

function Greeting() {
  return (
    <section className="greeting page__block page__block_cover page__block_cover_white">
      <Image className="page__block-img" src={greetingBg} alt="Обращение руководителя" placeholder="blur" />
      <div className="page__block-body">
        <Title type="h2" decor={false} className="greeting__title-top">
          Дорогие друзья!
        </Title>

        <div className="greeting__body">
          <div className="greeting__col">
            <Image className="greeting__photo" src={rector} alt="Аллабян Максим Геннадьевич" placeholder="blur" />

            <div className="greeting__post">
              Кандидат экономических наук, доцент, федеральный эксперт Министерства науки и&nbsp;высшего образования Российской Федерации, ректор института &laquo;Высшая школа
              предпринимательства&raquo;
            </div>
            <div className="greeting__name">Аллабян Максим Геннадьевич</div>
          </div>
          <div className="greeting__col wysiwyg">
            <Title type="block" decor={false} className="greeting__title">
              Дорогие друзья!
            </Title>
            <div className="greeting__text">
              В&nbsp;жизни каждого человека рано или поздно наступает момент, когда нужно сделать решающий выбор,&nbsp;&mdash; в&nbsp;каком вузе учиться и&nbsp;какую профессию получить.
              От&nbsp;качества полученного образования зависит профессиональный рост и&nbsp;самореализация выпускников. Поэтому институт &laquo;Высшая школа предпринимательства&raquo; предлагает
              абитуриентам и&nbsp;студентам обучение по&nbsp;актуальным и&nbsp;востребованным специальностям на&nbsp;трех уровнях образования&nbsp;&mdash; бакалавриата, магистратуры
              и&nbsp;аспирантуры. Важнейший навык, которому учит наш институт и&nbsp;который безмерно ценят работодатели,&nbsp;&mdash; умение применять полученные знания на&nbsp;практике. После
              выпуска наши студенты становятся конкурентоспособными специалистами, которые легко находят работу и&nbsp;успешно строят карьеру.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Greeting;
