import { Title } from "@vshp/ui-components";
import applicantsBg from "../../../public/images/applicants_hero.jpg";
import Link from "next/link";
import Image from "next/image";

function Applicants({ slug }) {
  return (
    <section className="page__block page__block_cover page__block_center page__block_cover_light">
      <Image className="page__block-img" src={applicantsBg} alt="Информация для абитуриентов" placeholder="blur" />
      <div className="page__block-body">
        <Title type="h2" decor={false}>
          Информация для абитуриентов
        </Title>

        <div className="page__block-subtitle">
          В данном разделе находится подробное описание образовательных программ, информацию о дополнительной подготовке, порядке поступления и оплате обучения, организации студенческой жизни.
        </div>
        <Link className="btn btn_primary" href={slug}>
          Приемная комиссия
        </Link>
      </div>
    </section>
  );
}

export default Applicants;
