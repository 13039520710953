import { Title, Section, Collapse } from "@vshp/ui-components";

function Faq() {
  return (
    <Section>
      <Title type="h2">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</Title>
      <Collapse>
        <Collapse.Item
          header="Что такое профессиональная переподготовка? Есть ли в вашем институте программы профессиональной переподготовки?"
          content="Профессиональная переподготовка&nbsp;&mdash; это получение дополнительных компетенций (знаний, умений, навыков, а&nbsp;также личностных качеств), необходимых для выполнения функций нового направления профессиональной деятельности или получения дополнительной квалификации. Данное направление обучения доступно лицам, имеющим среднее или высшее профессиональное образование. Профессиональная переподготовка&nbsp;&mdash; удобный, недорогой&nbsp;и, главное, быстрый способ (программа включает только профильные дисциплины) получить второе образование, освоить новую специальность. Этим она выгодно отличается от&nbsp;второго высшего образования. По&nbsp;завершении программы студентам выдается диплом установленного образца о&nbsp;профессиональной переподготовке."
        />
        <Collapse.Item
          header="Где можно ознакомиться с официальной информацией о вашей организации?"
          content={`<a href="https://institute.vshp.online/" target="_blank" rel="noopener noreferrer">На официальном сайте</a> образовательной организации, в соответствии с утвержденным приказом Федеральной службы по надзору в сфере образования и науки от 29.05.2014 года №785, размещена вся необходимая информация о наших организациях в разделе «Сведения об образовательной организации».`}
        />
        <Collapse.Item
          header="Мне надо получить справку об обучении. Как это сделать?"
          content="Обучающимся на&nbsp;очной форме необходимо подойти в&nbsp;учебную часть и&nbsp;оставить заявку на&nbsp;получение требуемого документа. Студенты заочной формы обучения могут оставить заявку своему куратору в&nbsp;личном кабинете на&nbsp;сайте. Срок подготовки документа зависит от&nbsp;вида документа, наличия визирующего лица и&nbsp;дня подачи. Средний срок ожидания документа 3&nbsp;рабочих дня. Получение документов возможно в&nbsp;здании образовательной организации, по&nbsp;почте либо курьером (для студентов заочной формы, обучающихся через интернет)."
        />
        <Collapse.Item
          header="Как я могу связаться с куратором?"
          content={`Связаться с&nbsp;куратором можно на&nbsp;сайте vshp.online через <a href="https://institute.vshp.online/" target="_blank" rel="noopener noreferrer">личный кабинет</a> или по&nbsp;телефону. <a href="https://institute.vshp.online/" target="_blank" rel="noopener noreferrer">Наши контакты</a>.`}
        />
        <Collapse.Item
          header="В чем разница между курсами повышения квалификации и профессиональной переподготовкой?"
          content="Курсы повышения квалификации помогут вам улучшить ваши умения и&nbsp;повысить уровень профессионализма в&nbsp;вашей сфере деятельности. С&nbsp;помощью курсов профессиональной переподготовки мы&nbsp;можете сменить профессию или получить новую."
        />
      </Collapse>
      <div className="text-center">
        <a className="btn btn_primary btn_space_top" href="https://vshp.online/faq" target="_blank" rel="noopener noreferrer">
          Посмотреть все вопросы
        </a>
      </div>
    </Section>
  );
}

export default Faq;
